import React, { Component } from 'react';
import Header from '../components/headerComponent/header';

 class Contact extends Component {
  render() {
    return (
         <div>
          <Header /> 
        test Contact

        </div>
     );
  }
}

export default Contact;