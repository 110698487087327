import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { NavLink, Col, Row, Container } from 'reactstrap';
import './footer.css';
import csLogo from '../../images/cs-logo-2018.svg';
import visLogo from '../../images/visions-logo.png';

class Footer extends Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
			isOpen: false
		};
	}
	toggle() {
		this.setState({
			isOpen: !this.state.isOpen
		});
	}
	render() {
		return (
			<div>
				<footer>
					<Container className="background">
						<Row>
							<Col md={{ size: 4, offset: 1 }}>
								<div className="logo-left">
									<NavLink href="/home">
										<img src={visLogo} className="visionsLogo  " alt="WhyCS Logo" />
									</NavLink>
								</div>
							</Col>

							{/* <Col xs="2" sm="4"></Col> */}

							<Col md={{ size: 4, offset: 1 }}>
								<div className="logo-right">
									<NavLink href="https://www.csforall.org" target="_blank">
										<img src={csLogo} className="CSLogo logo-right" alt="CSforALL Logo" />
									</NavLink>
								</div>
								<br />
								<div className="privacy">
									<NavLink href="https://www.csforall.org/privacy" target="_blank">
										<p className="color">Privacy Policy</p>
									</NavLink>
								</div>
							</Col>
						</Row>
					</Container>

					{/* </div> */}

					{/* <NavbarToggler onClick={this.toggle} /> */}
					{/* <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              
              <NavItem>
                <NavLink href="/">Home</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/about">About</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/startwhycsquiz">Take the Quiz</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/resources">Unplugged Resources</NavLink>
              </NavItem>
           
            </Nav>
          </Collapse> 
        </Navbar>*/}
				</footer>
			</div>
		);
	}
}

export default Footer;
