import React, { Component } from 'react';
import { Progress } from 'reactstrap';
import _ from 'lodash';

import './allie.css';

import bubble from '../../images/bubble.svg';
import allie1 from '../../images/allie1.png';
import allie2 from '../../images/allie2.png';
import allie3 from '../../images/allie3.png';
import allie4 from '../../images/allie4.png';
import allie5 from '../../images/allie5.png';
import allie11 from '../../images/allie11.png';
import allie19 from '../../images/allie19.png';

export class Allie extends Component {
	randomnizeList = () => {
		let randomAllie = _.shuffle([ allie1, allie2, allie3, allie4, allie5 ])[0];
		return randomAllie;
	};
	render() {
		const { questionNumber, loadingBarLength } = this.props;
		switch (questionNumber) {
			//conditional rendering for the allie component along with the text
			//the case represents each breakpoint (0,5,11,[18-20],default case)
			//default will randomize Allie ( the png )
			case 0:
				return (
					<div>
						<img src={allie1} className="allie" alt="Allie Robot" />
						<div className="speechBubble">
							<img src={bubble} className="bubble" alt="thought bubble" />
							<div className="speechText">
								I'm Allie, your robot friend.
								<br />
								Let's get started!
								<div className="lineSpacer" />
								Which statement better reflects your core values?
								{/* <Progress color="success" value={questionNumber} max={loadingBarLength} /> */}
							</div>
						</div>
					</div>
				);
			case 5:
				return (
					<div>
						<img src={allie5} className="allie" alt="Allie Robot" />
						<div className="speechBubble2">
							<img src={bubble} className="bubble2" alt="thought bubble" />

							<div className="speechText2">
								Those are great choices! You’re about a quarter of the way done!
								<br />
							</div>
							<div className="progressBarDiv">
								<Progress color="success" value={questionNumber} max={loadingBarLength} />
							</div>
						</div>
					</div>
				);
			case 11:
				return (
					<div>
						<img src={allie11} className="allie" alt="Allie Robot" />
						<div className="speechBubble2">
							<img src={bubble} className="bubble2" alt="thought bubble" />

							<div className="speechText2">
								Your values really inspire me! Half-way there!
								<br />
							</div>
							<div className="progressBarDiv">
								<Progress color="success" value={questionNumber} max={loadingBarLength} />
							</div>
						</div>
					</div>
				);
			case 18 || 19 || 20:
				return (
					<div>
						<img src={allie19} className="allie" alt="Allie Robot" />
						<div className="speechBubble2">
							<img src={bubble} className="bubble2" alt="thought bubble" />

							<div className="speechText2">
								You're on your way! Keep answering.
								<br />
							</div>
							<div className="progressBarDiv">
								<Progress color="success" value={questionNumber} max={loadingBarLength} />
							</div>
						</div>
					</div>
				);

			default:
				return (
					<div>
						<img src={this.randomnizeList()} className="allie" alt="Allie Robot" />
						<div className="speechBubble2">
							<img src={bubble} className="bubble2" alt="thought bubble" />

							<div className="speechText2">
								You're on your way! Keep answering.
								<br />
							</div>
							<div className="progressBarDiv">
								<Progress color="success" value={questionNumber} max={loadingBarLength} />
							</div>
						</div>
					</div>
				);
		}
	}
}

//conditional returns || render
export default Allie;
