import React, { Component } from 'react';

import { Button } from 'reactstrap';
import { Redirect } from 'react-router';
import axios from 'axios';
import '../pages/pages.css';
import Header from '../components/headerComponent/header';
import Footer from '../components/footerComponent/footer';
import CategorySquare from '../components/categorySquare';
import CategoryInformation from '../components/categoryInformation';
import CategoryBlocksHeader from '../components/categoryBlocksHeader';
import SocialMediaLinks from '../components/socials';
import ActivityDeck from '../unpluggedResources/CS_Visions_Unplugged_Activity_Deck_CSforALL.pdf';
import UnpluggedCards from '../unpluggedResources/CS_Visions_Unplugged_Activity_Cards_CSforALL.pdf';

import { INPUTS } from '../const';

const url = process.env.REACT_APP_API_URL;

var localInstance = axios.create({
	// method: 'post',
	baseURL: url,
	headers: {
		Accept: 'application/json',
		'Access-Control-Allow-Origin': '*'

		//'Authorization': 'Bearer '+ USER_TOKEN
	}
});

//to do get all results from db put into the state map thingy
//or a pie graph chart

class Profile extends Component {
	constructor(props) {
		super(props);
		this.toggle = this.toggle.bind(this);
		this.state = {
			fireRedirect: false,
			isOpen: false,
			counts: {},
			info: {},
		};
	}
	toggle() {
		this.setState({
			isOpen: !this.state.isOpen
		});
	}
	// these warn functions are to avoid a "should already be working" error in react that is so far only triggered in firefox
	//Error: https://reactjs.org/docs/error-decoder.html/?invariant=327
	//Issue: https://github.com/facebook/react/issues/17355
	warnMissingInitialInputs() {
		alert('Please complete the initial form and all of the quiz to access the results page');
	}
	warnInvalidVoteCountsMsg() {
		alert("It seems there was an error processing your responses. There seems to be either too many or too few. Please go back to the quiz start and try again. If this persists email web@csforall.org about this issue");
	}
	getVoteCount(category) {
		const vote = localStorage.getItem(category);
		var result = vote === null ? 0 : parseInt(vote);
		return result;
	}
	validateToSend() {
		const countsArr = Object.values(this.state.counts);
		const countSum = countsArr.reduce((prev, cur) => prev + cur);
		const initval = 1;
		const allValidSize = countsArr.reduce((prev, cur) => {
			return prev && (cur <= 6)
		}, initval);

		console.log("counts - ", this.state.counts);

		if (countSum === 21 && allValidSize) {
			return true
		} else {
			return false
		}	
	}
	sendIfValid() {
		const valid = this.validateToSend();
		if (!valid) { 
			// TODO - display the above message instead of values. 
			this.setState({ fireRedirect: true }, this.warnInvalidVoteCountsMsg)
		} else {
			const requestAlreadySent = localStorage.getItem('sent'); // we assume a page reload took place. 
			if (requestAlreadySent){
				console.log("not sending responses since request is marked as sent by client");
				return
			}
			const info = this.state.info;
			const counts = this.state.counts;
			const chosenQIdFollowedByRejectedQId = localStorage.getItem('chosenRejected');

			localInstance
				.post('/user/response', {
					EWD: counts.ewdcount,
					CC: counts.cccount,
					CL: counts.clcount,
					TSS: counts.tsscount,
					ESJ: counts.esjcount,
					SRI: counts.sricount,
					PAJ: counts.pajcount,
					zip: info.zip,
					careerField: info.career,
					ageRange: info.ageRange,
					gender: info.gender,
					misc: chosenQIdFollowedByRejectedQId
				})
				.then((response) => {
					console.log(`server msg: \n${JSON.stringify(response.data.msg)}`);
					if (!response.data.err) {
						localStorage.setItem("sent", "true");
					} else {
						// TODO - update once validation is introduced in backend. 
						console.log(`error: \n${JSON.stringify(response.data.err)}`)
					}
				})
				.catch((error) => {
					console.log(`Error posting \n${error}`);
				});
		}
	}
	checkDemographicInputs(info) {
		if (info === null) {
			this.setState({ fireRedirect: true }, this.warnMissingInitialInputs);
		}
	}
	componentDidMount() {
		const info = JSON.parse(localStorage.getItem(INPUTS.inputs));
		this.checkDemographicInputs(info);	
		var counts = {};
		counts.ewdcount = this.getVoteCount('EWD');
		counts.cccount = this.getVoteCount('CC');
		counts.clcount = this.getVoteCount('CL');
		counts.tsscount = this.getVoteCount('TSS');
		counts.esjcount = this.getVoteCount('ESJ');
		counts.sricount = this.getVoteCount('SRI');
		counts.pajcount = this.getVoteCount('PAJ');

		this.setState({counts, info}, this.sendIfValid);
		}
	componentWillUnmount() {
		// fires immediately before component is unmounted
		// from DOM (removed)
		localStorage.clear();
	}

	render() {
		const { from } = this.props.location.state || '/';

		const { fireRedirect } = this.state;
		return (
			<div>
				<Header />
				<div className="resultsWrapper">
					<h3>Your core values around why we should teach computer science are...</h3>

					<CategorySquare /> {/* TODO - user props to pass down vote count. Currently reads localstorage on its own.*/}

					{/* buttons */}
					<div className="learnMore-container">
						<Button className="btn text-dark learnMoreButton" role="button" href="#impactAreas">
							LEARN MORE ABOUT<br /> THE CORE VALUES
						</Button>

						<Button
							href="#impactAreasColleagues"
							// target="_blank"
							className="btn text-dark learnMoreButton"
							role="button"
						>
							DO THIS WITH YOUR <br /> COLLEAGUES
						</Button>
						<br />

						<Button
							href="https://firebasestorage.googleapis.com/v0/b/summit-website-production.appspot.com/o/visions%2FSanto_Vogel_Ching_2019_CS4What_Diverse_Visions_of_Computer_Science_Education_in_Practice.pdf?alt=media&token=cd892dba-2859-4793-9250-59cda95e02bf"
							target="_blank"
							className="btn text-dark learnMoreButton"
							role="button"
						>
							DOWNLOAD THE 'CS for What' <br /> WHITEPAPER
						</Button>
					</div>

					{/* socials */}
					<div className="SocialsContainer">
						<SocialMediaLinks />
					</div>

					<div className="impactAreas" id="impactAreas">
						<h1 className="resourcesTitle">Learn More About Values and Impacts Around CSed</h1>
						<div className="red-divider"> &nbsp; </div>
						<p className="program-info">
							Even with many different reasons being given for computer science education, there are some
							common themes across them. In CSforALL’s white paper,
							<a
								href="https://firebasestorage.googleapis.com/v0/b/summit-website-production.appspot.com/o/visions%2FSanto_Vogel_Ching_2019_CS4What_Diverse_Visions_of_Computer_Science_Education_in_Practice.pdf?alt=media&token=cd892dba-2859-4793-9250-59cda95e02bf"
								rel="noopener noreferrer"
								target="_blank"
							>
								CS for What? Diverse Visions of Computer Science Education in Practice
							</a>, Santo, Vogel and Ching (2019) put forward the CSed Visions Framework, which found that
							most arguments touched on one or more of seven areas of possible impact.
						</p>
						<br />
						<br />
						<CategoryBlocksHeader />
					</div>

					<div>
						<CategoryInformation />
					</div>

					<br />
					<br />
					<div className="impactAreas" id="impactAreasColleagues">
						<h1 className="resourcesTitle">Do this with your colleagues!</h1>
						<div className="red-divider"> &nbsp; </div>
						<div className="lineSpacer" />
						<p className="resourcesText">
							It’s fun to take this little quiz and see the results, but to really inform your work, take
							it one step further - hold a conversation with your colleagues to learn more about what
							values you share, and where you might see things differently.
						</p>
						<p className="resourcesText">
							If you’re a district leader, principal, teacher, researcher or designer interested in CS
							education, you can use the resources below to go through an ‘unplugged’ CS Visions activity,
							and think through how your values can shape the choices you make around CS education.
						</p>
					</div>
					<br />

					<a
						className=" btn resourcesButton"
						role="button"
						href={ActivityDeck}
						download="CS_Visions_Unplugged_Activity_Slides.pdf"
					>
						DOWNLOAD THE CS VISIONS <br />ACTIVITY SLIDE DECK
					</a>

					<a
						className="btn resourcesButton"
						role="button"
						href={UnpluggedCards}
						download="CS_Visions_Unplugged_Cards.pdf"
					>
						DOWNLOAD THE CS VISIONS <br />STATEMENT CARDS
					</a>
				</div>
				<Footer />
				{fireRedirect && <Redirect to={from || '/quiz/start'} />}
			</div>
		);
	}
}

export default Profile;