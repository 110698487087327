// firebase google analytics
import firebase from 'firebase/compat/app';

// Add the Firebase services that you want to use
// import 'firebase/firestore'
import 'firebase/compat/auth'
// import 'firebase/storage'
import 'firebase/compat/analytics'
// import { getAnalytics, logEvent } from "firebase/analytics";

import 'firebase/compat/functions'

const prodConfig = {
  apiKey: "AIzaSyBfAiRfOU2yFQXO5nJ51dF8lhecIhRSftA",
  authDomain: "visions-csforall.firebaseapp.com",
  databaseURL: "https://visions-csforall.firebaseio.com",
  projectId: "visions-csforall",
  storageBucket: "visions-csforall.appspot.com",
  messagingSenderId: "414138009857",
  appId: "1:414138009857:web:6c8ec66bf2c302ceb17300",
  measurementId: "G-030LT19YJV"
};

const devConfig = {
	apiKey: "AIzaSyBfAiRfOU2yFQXO5nJ51dF8lhecIhRSftA",
	authDomain: "visions-csforall.firebaseapp.com",
	databaseURL: "https://visions-csforall.firebaseio.com",
	projectId: "visions-csforall",
	storageBucket: "visions-csforall.appspot.com",
	messagingSenderId: "414138009857",
	appId: "1:414138009857:web:0baf414de32683e5b17300",
	measurementId: "G-Y1PZ320B5T"
  };

const firebaseConfig = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

// console.log('You are in ', process.env.NODE_ENV, 'Mode')
firebase.initializeApp(firebaseConfig);

// export { firebase, analytics };
export { firebase };
