import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { NavLink, Col, Row, Container } from 'reactstrap';
import './footer.css';
import visLogo from '../../images/visions-logo.png';

class Footer extends Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
			isOpen: false
		};
	}
	toggle() {
		this.setState({
			isOpen: !this.state.isOpen
		});
	}
	render() {
		return (
			<div>
				<footer>
					<Container className="background">
						<Row>
							<Col xs="2" sm="2" />
							<Col xs="8" sm="6">
								<NavLink href="/home">
									<img src={visLogo} className="visionsLogo" alt="WhyCS Logo" />
								</NavLink>
							</Col>
							<Col sm="4">
								<div className="privacyLogo">
									<NavLink href="https://www.csforall.org/privacy" target="_blank">
										<p className="color">Privacy Policy</p>
									</NavLink>
								</div>
							</Col>
						</Row>
					</Container>
				</footer>
			</div>
		);
	}
}

export default Footer;
// https://www.csforall.org/privacy/
