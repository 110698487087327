import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

//import 'circular-std';
import About from './pages/about';
import Contact from './pages/contact';
import QuizStart from './pages/quizStart';
import Resources from './pages/resources';
import Results from './pages/results';
import Home from './pages/homepage';

import Quiz from './components/questionComponent/quiz';

import { firebase } from "./firebaseConfig/firebase";

// TO DO: unused but necessary for analytics collection 
// eslint-disable-next-line 
const analytics = firebase.analytics();

class App extends Component {
	render() {
		return (
			<Router>
				<div>
					<Switch>
						<Route exact path="/about" component={About} />
						<Route exact path="/home" component={Home} />
						<Route path="/" component={Home} exact />
						<Route path="/contact" component={Contact} />
						<Route path="/quiz/start" component={QuizStart} />
						<Route path="/resources" component={Resources} />
						<Route path="/results" component={Results} />
						<Route path="/quiz" exact component={Quiz} />
						<Route component={Error} />
					</Switch>
				</div>
			</Router>
		);
	}
}

export default App;
