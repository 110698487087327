var quizQuestions = [
	{
		category: 'EWD',

		questions: [
			{
				qID: 'EWD1',
				catID: 'EWD',
				qText: 'There is a shortage of engineers and programmers that needs to be filled.'
			},
			{
				qID: 'EWD2',
				catID: 'EWD',
				qText: 'Computing may provide our youth with more and better career opportunities to choose from.'
			},
			{
				qID: 'EWD3',
				catID: 'EWD',
				qText: 'Computational thinking will be key no matter what career youth end up in. '
			},
			{
				qID: 'EWD4',
				catID: 'EWD',
				qText:
					'It will strengthen local economies by attracting companies looking for technologically competent workers.'
			}
		]
	},
	{
		category: 'ESJ',
		questions: [
			{
				qID: 'ESJ1',
				catID: 'ESJ',
				qText:
					'There are major disparities in minorities’ and young women’s engagement in STEM fields and universal CSed is part of addressing that.'
			},
			{
				qID: 'ESJ2',
				catID: 'ESJ',
				qText:
					'It will level the playing field and help close the "digital divide" around tech for lower income youth.'
			},
			{
				qID: 'ESJ3',
				catID: 'ESJ',
				qText:
					'Not all tech will be in the best interest of our students -  they’ll need be able to think critically about technology platforms. It’s a “program or be programmed” world out there!'
			},
			{
				qID: 'ESJ4',
				catID: 'ESJ',
				qText:
					'Our technology is largely designed by economically, racially and socially privileged groups, and their biases and blind spots get embedded in our tech. CSed can help.'
			}
		]
	},
	{
		category: 'CC',
		questions: [
			{
				qID: 'CC1',
				catID: 'CC',
				qText: 'Being a good citizen in the 21st century will include digital citizenship.'
			},
			{
				qID: 'CC2',
				catID: 'CC',
				qText: 'Youth shouldn’t just be consumers but also producers of technology. '
			},
			{
				qID: 'CC3',
				catID: 'CC',
				qText:
					'Informed citizens need to understand the basics of how the technological world works in order to contribute productively to society as a whole. '
			},
			{
				qID: 'CC4',
				catID: 'CC',
				qText:
					'Political and cultural participation are increasingly shaped by computing and our students need to understand social impacts of tech.'
			}
		]
	},
	{
		category: 'CL',
		questions: [
			{
				qID: 'CL1',
				catID: 'CL',
				qText: 'It promotes 21st century skills like creativity, collaboration and communication.'
			},
			{
				qID: 'CL3',
				catID: 'CL',
				qText:
					'it has students engage in design thinking - identifying problems and then prototyping, testing and iterating on solutions.'
			},
			{
				qID: 'CL3',
				catID: 'CL',
				qText: 'Knowing how to code is a new form of literacy.'
			},
			{
				qID: 'CL4',
				catID: 'CL',
				qText:
					'It can promote systems thinking - the ability to understand and intervene in complex systems that are ubiquitous in our world.'
			}
		]
	},
	{
		category: 'PAJ',
		questions: [
			{
				qID: 'PAJ1',
				catID: 'PAJ',
				qText: 'Computing provides youth with the ability to express themselves creatively and have voice. '
			},
			{
				qID: 'PAJ2',
				catID: 'PAJ',
				qText: 'Being able to understand and make technologies gives kids power and agency.'
			},
			{
				qID: 'PAJ3',
				catID: 'PAJ',
				qText: 'Creating new technologies like apps, websites or robots is fun! '
			},
			{
				qID: 'PAJ4',
				catID: 'PAJ',
				qText: 'The process of tinkering and making can lead to wonder, discovery and enjoyment.'
			}
		]
	},
	{
		category: 'TSS',
		questions: [
			{
				qID: 'TSS1',
				catID: 'TSS',
				qText:
					'It’s important that people in local communities be equipped to address their own problems through having technologically fluent community members. '
			},
			{
				qID: 'TSS2',
				catID: 'TSS',
				qText:
					'The more people we have that understand computer science, the more innovations and new knowledge we can produce as a society.'
			},
			{
				qID: 'TSS3',
				catID: 'TSS',
				qText:
					'We need to produce scientific and technological innovations that solve ‘wicked’ problems such as climate change and cybersecurity.'
			},
			{
				qID: 'TSS4',
				catID: 'TSS',
				qText:
					'Technological innovation helps promote human flourishing - the next generation needs to know how to do it!'
			}
		]
	},
	{
		category: 'SRI',
		questions: [
			{
				qID: 'SRI1',
				catID: 'SRI',
				qText:
					'Teaching CS is a compelling new area that teachers are interested in and is a place where they can experiment with pedagogy.'
			},
			{
				qID: 'SRI2',
				catID: 'SRI',
				qText:
					'CSed often uses project-based approaches that can enhance school pedagogy and move away from sage on the stage approaches.'
			},
			{
				qID: 'SRI3',
				catID: 'SRI',
				qText:
					'Practices from CS might enhance student learning of traditional academic disciplines (e.g. - introducing computer simulation of an ecosystem to learn concepts in ecology, or using CS concepts to learn algebra).'
			},
			{
				qID: 'SRI4',
				catID: 'SRI',
				qText: 'Making technology is fun, so bringing CS to schools can increase student engagement.'
			}
		]
	}
];

export default quizQuestions;
