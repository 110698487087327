import React, { Component } from 'react';
import '../pages/pages.css';
// import Header from '../components/headerComponent/header'
import FooterA from '../components/footerComponent/footerBoth';

import { Card, Label, Button, Row, Col, CardTitle } from 'reactstrap';
import { AvForm, AvField, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import { Redirect } from 'react-router';

import { INPUTS } from '../const';

class QuizStart extends Component {
	constructor(props) {
		super(props);
		
		this.handleValidSubmit = this.handleValidSubmit.bind(this);
		this.state = {
			fireRedirect: false
		};
		//clear local storage
		localStorage.clear();
	}

	//fireRedirect and values are updated
	//default event which has input values and values
	handleValidSubmit(event, values) {
		event.preventDefault();
		localStorage.setItem(INPUTS.inputs, JSON.stringify(values));
		this.setState({
			fireRedirect: true,
			values
		});
	}

	render() {
		const { from } = this.props.location.state || '/';

		const { fireRedirect } = this.state;

		return (
			<div>
				{/* <Header /> */}
				<Row>
					<Col xs={12} md={6}>
						<div className="aboutWrapper">
							<div className="lineSpacerA" />
							<div className="lineSpacerA" />
							<p className="intro">
								We created this quiz to highlight the importance of core values when it comes to
								computer science education. Different reasons for teaching computer science aren’t just
								important so that we can get other people to care about CS, they should also shape what
								our computer science classes look like - who’s in them, what kinds of things get taught
								and in what ways. Our values should be expressed in our practice.
							</p>

							<br />
							<br />
						</div>
					</Col>

					<Col>
						<div className="lineSpacerA" />
						<div className="lineSpacerA" />

						{/* <Row> */}
						{/* <Col sm="12" md={{ size: 8, offset: 2 }}> */}
						<CardTitle>
							<p className="query">Please enter some information</p>
						</CardTitle>
						<Card body className="text-center" inverse style={{ borderColor: 'white' }}>
							<br />

							{/* <Row>  */}
							<Col xs={12} md={10}>
								<AvForm className="text-form" onValidSubmit={this.handleValidSubmit}>
									<AvField
										className="intake-form"
										name="zip"
										label="ZIP"
										type="number"
										errorMessage="Please enter your zip code"
										validate={{
											number: true,
											minLength: { value: 5 },
											maxLength: { value: 5 },
											required: { value: true }
										}}
									/>
									<p className="small">International participants enter 00000</p>

									<AvField
										type="select"
										className="intake-form"
										name="career"
										label="I am an..."
										validate={{
											required: { value: true }
										}}
										errorMessage="Please select a role"
									>
										<option />
										<option>School-based Educator</option>
										<option>Out-of-School Educator</option>
										<option>School building leader</option>
										<option>District Administrator</option>
										<option>Policymaker</option>
										<option>Researcher/Higher Education</option>
										<option>Curriculum/Professional Development Provider</option>
										<option>Other</option>
									</AvField>

									<Label>GENDER</Label>
									<AvRadioGroup
										className="text"
										inline
										name="gender"
										errorMessage="Please enter your gender"
										required
									>
										<AvRadio label="Female" value="female" />
										<AvRadio label="Male" value="male" />
										<AvRadio label="Other" value="other" />
									</AvRadioGroup>

									<AvField
										type="select"
										className="intake-form"
										name="ageRange"
										label="AGE RANGE"
										validate={{
											required: { value: true }
										}}
										errorMessage="Please select an age group"
									>
										<option />

										<option>18 or less</option>
										<option>19-25</option>
										<option>26-35</option>
										<option>36-45</option>
										<option>46-55</option>
										<option>55+</option>
									</AvField>
									<div className="lineSpacerA" />

									<Button className="quizStartButton">START QUIZ</Button>
									<div className="lineSpacerA" />
									<div className="lineSpacerA" />
								</AvForm>
							</Col>
						</Card>
					</Col>
				</Row>
				{/*JSON.stringify(this.state.values)*/}

				{/* prints out :
        {"zip":"12132","career":"asdasd","gender":"male","select":"19-25"}
         */}

				<FooterA />

				{fireRedirect && <Redirect to={from || '/quiz'} />}
			</div>
		);
	}
}

export default QuizStart;
