import React, { Component } from 'react'
import Header from '../components/headerComponent/header'
import './pages.css';
// import { NavLink, Button } from 'reactstrap';
import Footer from '../components/footerComponent/footer';
// import { Button } from 'reactstrap';

import ActivitySlides from '../unpluggedResources/CS_Visions_Unplugged_Activity_Deck_CSforALL.pdf';
import UnpluggedCards from '../unpluggedResources/CS_Visions_Unplugged_Activity_Cards_CSforALL.pdf';
 
//  import VisionsStatments from '../unpluggedResources/WhyCS Visions Statements Handout.pdf';

 class Resources extends Component {
  render() {
    return (
      <div>
        <Header />

        <div className="resultsWrapper">




        <div className="impactAreas" id='impactAreas'>
            <h1 className='resorcesTitle'>Do WhyCS with your colleagues!</h1>
            <div className="red-divider"> &nbsp;  </div>
            <div className='lineSpacer'></div>
            <p className="resourcesText">
            It’s fun to take this little quiz and see the results, but to really inform your work, take it one step further - hold a conversation with your colleagues to learn more about what values you share, and where you might see things differently.
            </p>
            <p className="resourcesText">
            If you’re a district leader, principal, teacher, researcher or designer interested in CD education, you can use the resources below to go through an ‘unplugged’ WhyCS activity, and think through how your values can shape the choices you make around CS education. 
            </p>
           </div>
            <br/>

            <a className=" btn resourcesButton" role="button" href={ActivitySlides} download="WhyCS Unplugged Activity Slides.pdf">DOWNLOAD THE WHYCS <br/>ACTIVITY SLIDE DECK</a>

           <a className="btn resourcesButton" role="button" href={UnpluggedCards} download="WhyCS Unplugged Cards.pdf">DOWNLOAD THE WHYCS <br/>STATEMENT CARDS</a>

      
        </div>


        <Footer />
      </div>
    )
  }
}

export default Resources
