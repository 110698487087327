import React, { Component } from 'react';
  import '../pages/pages.css';
 
var data = [
            //EWD
             {name: 'Economic & Workforce Development', id:'blue'},
            //CC
            {name: 'Citizenship & Civic Engagement', id:'green'},
            // CL
            {name: 'Competencies & Literacies', id:'purple'},
             //TSS
            {name: 'Technological, Social & Scientific Innovation', id:'pink'},
             //ESJ
            {name: 'Equity & Social Justice', id:'red'},
            //SRI
            {name: 'School Reform & Improvement', id:'orange'},
            //PAJ
            {name: 'Personal Agency, Joy & Fulfillment', id:'yellow'}
];

class CategorySquare extends Component {
    constructor(props) {
        // fires before component is mounted
        super(props); // makes this refer to this component
        this.state = {      
            fireRedirect: false,
            r1ID: '',
            r1Text: '',
            r2ID: '',
            r2Text: '',
            r3ID: '',
            r3Text: '',
       
          };
    }
    componentDidMount() {
      

        //import the window storage categories 
          var ewscount = localStorage.getItem('EWD');
          var cccount = localStorage.getItem('CC');
          var clcount = localStorage.getItem('CL');
          var tsscount = localStorage.getItem('TSS');
          var esjcount = localStorage.getItem('ESJ');
          var sricount = localStorage.getItem('SRI');
          var pajcount = localStorage.getItem('PAJ');
 
        //make and array then sort
          var responses = [
                    {name: 'EWD', count: ewscount},
                    {name: 'CC', count: cccount},
                    {name: 'CL', count: clcount},
                    {name: 'TSS', count: tsscount},
                    {name: 'ESJ', count: esjcount},
                    {name: 'SRI', count: sricount},
                    {name: 'PAJ', count: pajcount}
                ];

                var responsesSort = responses.sort(function (a, b) {
                    return (b.count - a.count );
                });

                 
              


        
        //determine q1 q2 q3
        

            //output
            // rank1 = [0]
            // rank2 = [2]
            // rank3  = [3]

         
            var Rank1 = responsesSort[0].name;
            var Rank2 = responsesSort[1].name;
            var Rank3 = responsesSort[2].name;

    switch (Rank1) {
      case 'EWD':
      this.setState({
          r1Text: data[0].name,
          r1ID: data[0].id
        });
        break;
      case 'CC':
      this.setState({
        r1Text: data[1].name,
        r1ID: data[1].id
        });
        break;
      case 'CL':
      this.setState({
        r1Text: data[2].name,
        r1ID: data[2].id
        });  
        break;
      case 'TSS':
      this.setState({
        r1Text: data[3].name,
        r1ID: data[3].id
        });  
        break;
      case 'ESJ':
      this.setState({
        r1Text: data[4].name,
        r1ID: data[4].id
        });
      
        break;
      case 'SRI':
      this.setState({
        r1Text: data[5].name,
        r1ID: data[5].id  
        });

        break;
      case 'PAJ':
      this.setState({
        r1Text: data[6].name,
        r1ID: data[6].id
        });
        break;
      default: // Do nothing
    }

    switch (Rank2) {
        case 'EWD':
        this.setState({
            r2Text: data[0].name,
            r2ID: data[0].id
          });
          break;
        case 'CC':
        this.setState({
          r2Text: data[1].name,
          r2ID: data[1].id
          });
          break;
        case 'CL':
        this.setState({
          r2Text: data[2].name,
          r2ID: data[2].id
          });  
          break;
        case 'TSS':
        this.setState({
          r2Text: data[3].name,
          r2ID: data[3].id
          });  
          break;
        case 'ESJ':
        this.setState({
          r2Text: data[4].name,
          r2ID: data[4].id
          });
        
          break;
        case 'SRI':
        this.setState({
          r2Text: data[5].name,
          r2ID: data[5].id  
          });
      
          break;
        case 'PAJ':
        this.setState({
          r2Text: data[6].name,
          r2ID: data[6].id
          });
          break;
        default: // Do nothing
      }
      
          switch (Rank3) {
            case 'EWD':
            this.setState({
                r3Text: data[0].name,
                r3ID: data[0].id
              });
              break;
            case 'CC':
            this.setState({
              r3Text: data[1].name,
              r3ID: data[1].id
              });
              break;
            case 'CL':
            this.setState({
              r3Text: data[2].name,
              r3ID: data[2].id
              });  
              break;
            case 'TSS':
            this.setState({
              r3Text: data[3].name,
              r3ID: data[3].id
              });  
              break;
            case 'ESJ':
            this.setState({
              r3Text: data[4].name,
              r3ID: data[4].id
              });
            
              break;
            case 'SRI':
            this.setState({
              r3Text: data[5].name,
              r3ID: data[5].id  
              });
          
              break;
            case 'PAJ':
            this.setState({
              r3Text: data[6].name,
              r3ID: data[6].id
              });
              break;
            default: // Do nothing
          }
          
                   

     //alert(Rank1); 
  }
  render() {


    return (

      <div className="categoryBlocks_wrapper">
        <div className='alignResults'>
          <div id={this.state.r1ID} className="resultsCategoryBlock"></div>
          <p className="resultsCategoryBlockText">{this.state.r1Text}</p>
        </div>
        <div className='alignResults'>
          <div id={this.state.r2ID} className="resultsCategoryBlock"></div>
          <p className="resultsCategoryBlockText">{this.state.r2Text} </p>
        </div>
        <div className='alignResults'>
          <div id={this.state.r3ID} className="resultsCategoryBlock"></div>
          <p className="resultsCategoryBlockText">{this.state.r3Text}</p>
        </div>
      </div>
    );
  }
}

export default CategorySquare;