
function assignStyleLeft(category){
    var btnClass = 'quizbtnL ';

    switch(category){
        case 'EWD':
            btnClass += 'bgBlue';
            break;
        case 'ESJ':
            btnClass +=  'bgRed';
            break;
        case 'CC':
            btnClass += 'bgGreen';
            break;
        case 'CL':
            btnClass +=  'bgPurple';
            break;
        case 'TSS':
            btnClass +=  'bgPink';
            break;
        case 'PAJ':
            btnClass +=  'bgYellow';
            break;
        case 'SRI':
            btnClass +=  'bgOrange';
            break;
        default: // Do nothing
    }
    return btnClass;

}

function assignStyleRight(category){
    var btnClass = 'quizbtnR ';

    switch(category){
        case 'EWD':
            btnClass += 'bgBlue';
            break;
        case 'ESJ':
            btnClass +=  'bgRed';
            break;
        case 'CC':
            btnClass += 'bgGreen';
            break;
        case 'CL':
            btnClass +=  'bgPurple';
            break;
        case 'TSS':
            btnClass +=  'bgPink';
            break;
        case 'PAJ':
            btnClass +=  'bgYellow';
            break;
        case 'SRI':
            btnClass +=  'bgOrange';
            break;
        default: // Do nothing
    }
    return btnClass;
}

export { assignStyleLeft, assignStyleRight };