import quizQuestions from './csQuestions';
import _ from 'lodash';

var tuples = _.shuffle([
	/* these are the 21 category comparisons needed to compare all categories
	Each category-level comparison is fixed in order [EWD vs ESJ, EWD vs CC, etc.] 
	What is shuffled is 
	1. the questions that represent the category for a given comparison
	2. Whether the categories are presented on the left or right of the screen.
	3. As of Dec 15 2021 - the order of the comparisons presented (EWD vs ESJ) is shuffled. 
	*/
	_.shuffle([ _.shuffle(quizQuestions[0].questions), _.shuffle(quizQuestions[1].questions) ]),
	_.shuffle([ _.shuffle(quizQuestions[0].questions), _.shuffle(quizQuestions[2].questions) ]),
	_.shuffle([ _.shuffle(quizQuestions[0].questions), _.shuffle(quizQuestions[3].questions) ]),
	_.shuffle([ _.shuffle(quizQuestions[0].questions), _.shuffle(quizQuestions[4].questions) ]),
	_.shuffle([ _.shuffle(quizQuestions[0].questions), _.shuffle(quizQuestions[5].questions) ]),
	_.shuffle([ _.shuffle(quizQuestions[0].questions), _.shuffle(quizQuestions[6].questions) ]),
	_.shuffle([ _.shuffle(quizQuestions[1].questions), _.shuffle(quizQuestions[2].questions) ]),
	_.shuffle([ _.shuffle(quizQuestions[1].questions), _.shuffle(quizQuestions[3].questions) ]),
	_.shuffle([ _.shuffle(quizQuestions[1].questions), _.shuffle(quizQuestions[4].questions) ]),
	_.shuffle([ _.shuffle(quizQuestions[1].questions), _.shuffle(quizQuestions[5].questions) ]),
	_.shuffle([ _.shuffle(quizQuestions[1].questions), _.shuffle(quizQuestions[6].questions) ]),
	_.shuffle([ _.shuffle(quizQuestions[2].questions), _.shuffle(quizQuestions[3].questions) ]),
	_.shuffle([ _.shuffle(quizQuestions[2].questions), _.shuffle(quizQuestions[4].questions) ]),
	_.shuffle([ _.shuffle(quizQuestions[2].questions), _.shuffle(quizQuestions[5].questions) ]),
	_.shuffle([ _.shuffle(quizQuestions[2].questions), _.shuffle(quizQuestions[6].questions) ]),
	_.shuffle([ _.shuffle(quizQuestions[3].questions), _.shuffle(quizQuestions[4].questions) ]),
	_.shuffle([ _.shuffle(quizQuestions[3].questions), _.shuffle(quizQuestions[5].questions) ]),
	_.shuffle([ _.shuffle(quizQuestions[3].questions), _.shuffle(quizQuestions[6].questions) ]),
	_.shuffle([ _.shuffle(quizQuestions[4].questions), _.shuffle(quizQuestions[5].questions) ]),
	_.shuffle([ _.shuffle(quizQuestions[4].questions), _.shuffle(quizQuestions[6].questions) ]),
	_.shuffle([ _.shuffle(quizQuestions[5].questions), _.shuffle(quizQuestions[6].questions) ])
]);

export default tuples;
