import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { NavLink, Row, Col, Container } from 'reactstrap';
import './footer.css';
import csLogo from '../../images/cs-logo-2018.svg';
// import visLogo from '../../images/visions-logo.png'

class Footer extends Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
			isOpen: false
		};
	}
	toggle() {
		this.setState({
			isOpen: !this.state.isOpen
		});
	}
	render() {
		return (
			<div>
				<footer>
					{/* <Navbar color="footerBar" light expand="md">
        <Container>
            <Row>
            <Col xs="3"></Col>
            <Col xs="auto">
           
             <NavLink href="https://www.csforall.org">
           <img src={csLogo} className="CSLogo logo-center" alt="CSforALL Logo" />
           </NavLink>

            
           </Col>
            <Col xs="3"></Col>
            </Row>
          </Container>
        </Navbar>
  */}

					<Container className="background">
						<Row>
							<Col xs="2" sm="4" />
							<Col xs="8" sm="4">
								<NavLink href="https://www.csforall.org" target="_blank">
									<img src={csLogo} className="CSLogo " alt="CSforALL Logo" />
								</NavLink>
							</Col>

							<Col sm="4">
								<div className="privacyLogo">
									<NavLink href="https://www.csforall.org/privacy" target="_blank">
										<p className="color">Privacy Policy</p>
									</NavLink>
								</div>
							</Col>
						</Row>
					</Container>
				</footer>
			</div>
		);
	}
}

export default Footer;
