import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { assignStyleLeft, assignStyleRight } from '../../utils/cardStyles';
import './questions.css';

var DEBUG = false;

export class Questions extends Component {
	state = { active: false };

	toggle() {
			this.setState({ active: !this.state.active });
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (DEBUG){
			console.log(prevProps);
		}
	}
	render() {
		var btnClassLeft = assignStyleLeft(this.props.qAcat);
		var btnClassRight = assignStyleRight(this.props.qBcat);
		return (
			<div>
				<div className="wrapper">
					<form className="text-form">
						<Button
							className={this.state.active ? `${btnClassLeft} left2` : `${btnClassLeft} left`}
							value={'A'}
							onClick={(e) => {
								this.toggle();
								this.props.handleValidSubmit(e, 'value');
							}}
						>
							<p className="buttonText" style={{ margin: '0 auto' }}>
								We should teach computer Science because...
							</p>
							<div> {this.props.qA} </div>
						</Button>

						<Button
							className={this.state.active ? `${btnClassRight} right2` : `${btnClassRight} right`}
							value={'B'}
							onClick={(e) => {
								this.toggle();

								this.props.handleValidSubmit(e, 'value');
							}}
						>
							<p className="buttonText" style={{ margin: '0 auto' }}>
								We should teach computer Science because...
							</p>
							<div> {this.props.qB} </div>
						</Button>
					</form>
				</div>
			</div>
		);
	}
}

export default Questions;
