import React, { Component } from 'react';
import { Redirect } from 'react-router';

import Allie from '../AllieComponent/Allie';
import tuples from '../../api/touples';
import FooterB from '../footerComponent/footerV';
import Questions from './Questions';

import '../../pages/pages.css';
import './questions.css';

import { INPUTS } from "../../const"

var DEBUG = false;

export class Quiz extends Component {
	constructor(props) {
		super(props);

		this.handleValidSubmit = this.handleValidSubmit.bind(this);
		this.state = {
			fireRedirect: false,
			goBackToIntake: false, 
			qA: '',
			qB: '',
			qAid: '',
			qBid: '',
			qAcat: '',
			qBcat: '',
			//this will be incremented to move to each question
			currentIndex: 0,
			//Used to check if the quiz is completed
			completed: false,
			active: true
		};
	}

	updateVote(category) {
		if (DEBUG && category === ""){
		  alert('this recorded a vote for the empty string \n go to the quiz.jsx file to disable these alerts');
		}
		const prevInCategory = localStorage.getItem(category);

		if(prevInCategory === null){	
			localStorage.setItem(category, 1);
		} else {
			var count = parseInt(prevInCategory);
			localStorage.setItem(category, count + 1);
		} 
	  }

	didCompleteIntakeForm(){
		return Boolean(localStorage.getItem(INPUTS.inputs));
	}
	warnMissingInitialInputs() {
		alert('Please go back to the initial page and complete the intake form to access the quiz');
		
	}


	componentDidMount() {
		//When component first fires off, these values will be set by default
		//will start with question at index 0(currentIndex)
		if (!this.didCompleteIntakeForm()) {
			this.setState({goBackToIntake: true}, this.warnMissingInitialInputs);
		}
		this.setState({
			qA: tuples[this.state.currentIndex][0][0].qText,
			qAcat: tuples[this.state.currentIndex][0][0].catID,
			qAid: tuples[this.state.currentIndex][0][0].qID,

			qB: tuples[this.state.currentIndex][1][0].qText,
			qBcat: tuples[this.state.currentIndex][1][0].catID,
			qBid: tuples[this.state.currentIndex][1][0].qID
		});
	}

	handleValidSubmit = (chosen) => {
		chosen.preventDefault();

		if (DEBUG) {
			console.log('chosen -', chosen.currentTarget.value);
		}
		const idA = this.state.qAid;
		const idB = this.state.qBid;

		//record the chosen button and the rejected button
		const chosenRejected = localStorage.getItem('chosenRejected');
		var prevChoices = chosenRejected == null ? "" : chosenRejected;

		if (chosen.currentTarget.value === 'A') {
			localStorage.setItem('chosenRejected', prevChoices + idA + idB + ',');
		} else {
			localStorage.setItem('chosenRejected', prevChoices + idB + idA + ',');
		}

		if (chosen.currentTarget.value === 'A') {
			if (DEBUG) {
				console.log('recorded vote - ', this.state.qAcat);
			}
			this.updateVote(this.state.qAcat);
		} else if (chosen.currentTarget.value === 'B') {
			if (DEBUG) {
				console.log('recorded vote - ', this.state.qBcat);
			}
			this.updateVote(this.state.qBcat);
		} else {
			if (DEBUG) {
				alert('Invalid vote');
			}
		}
		this.setState({
			currentIndex: this.state.currentIndex + 1, 
			fireRedirect: true 
		}, this.loadNextQs);
	};
	loadNextQs = () => {
		//if the current index is less than the array length -1
		//previously we checked for the first question at index 0, now we are going from
		//index 1-20
		if (this.state.currentIndex <= tuples.length - 1) {
			this.setState({
				qA: tuples[this.state.currentIndex][0][0].qText,
				qAcat: tuples[this.state.currentIndex][0][0].catID,
				qAid: tuples[this.state.currentIndex][0][0].qID,

				qB: tuples[this.state.currentIndex][1][0].qText,
				qBcat: tuples[this.state.currentIndex][1][0].catID,
				qBid: tuples[this.state.currentIndex][1][0].qID
			});
			//once we reach the end, we have completed the iterations
		} else {
			this.setState({ completed: true });
		}
	};
	
	render() {
		const { from } = this.props.location.state || '/';
		const { fireRedirect, goBackToIntake } = this.state;

		return (
			<div>
				<Allie questionNumber={this.state.currentIndex} loadingBarLength={tuples.length} />
				<br />

				<Questions
					qA={this.state.qA}
					qAid={this.state.qAid}
					qAcat={this.state.qAcat}
					qB={this.state.qB}
					qBid={this.state.qBid}
					qBcat={this.state.qBcat}
					handleValidSubmit={this.handleValidSubmit}
					active={this.state.props}
				/>
				<FooterB />
				{this.state.completed ? fireRedirect && <Redirect to={from || '/results'} /> : null}
				{goBackToIntake && <Redirect to={'/quiz/start'} />}
			</div>
		);
	}
}
//every time the number increases call the component
export default Quiz;
